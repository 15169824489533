export default {
  LOAD_LIST(state, data) {
    state.listaFeriados = data
  },
  PREPARE_TO_EDIT(state, data) {
    state.feriado = data
  },
  RESET(state) {
    state.feriado = {
      id: null,
      descricao: '',
      data: '',
      ativo: true,
    }
  },
}
