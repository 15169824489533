export default [
  {
    path: '/settings/protocol',
    name: 'protocolSettings',
    redirect: '/settings/protocol/protocolTypeSettings',
    component: () => import('@/views/settings/protocol/ProtocolSettings.vue'),
    meta: {
      action: 'ROLE_CORE_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Cadastro Protocolos',
      breadcrumb: [
        {
          text: 'Protocolo'
        }
      ]
    },
    children: [
      {
        path: 'protocolTypeSettings',
        name: 'protocolTypeSettings',
        component: () =>
          import('@/views/settings/protocol/ProtocolTypeSetting.vue'),
        meta: {
          action: 'ROLE_CORE_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Protocolos',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Protocolo | Tipos Protocolos'
            }
          ]
        }
      },
      {
        path: 'mediumProtocolSettings',
        name: 'mediumProtocolSettings',
        component: () =>
          import('@/views/settings/protocol/MediumProtocolSetting.vue'),
        meta: {
          action: 'ROLE_CORE_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Protocolos',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Protocolo | Protocolo Meios'
            }
          ]
        }
      },
      {
        path: 'itemSettings',
        name: 'itemSettings',
        component: () => import('@/views/settings/protocol/ItemSetting.vue'),
        meta: {
          action: 'ROLE_CORE_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Protocolos',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Protocolo | Item'
            }
          ]
        }
      }
    ]
  }
]
