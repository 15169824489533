export default {
  listProfiles: [],
  profile: {
    nome: '',
    roles: [
      {
        id: ''
      }
    ]
  },
  systemPermission: []
}
