export default [
  {
    path: '/settings/ordemServico',
    name: 'ordemServicoSettings',
    redirect: '/settings/ordemServico/statusOsSettings',
    component: () =>
      import('@/views/settings/ordemServico/OrdemServicoSettings.vue'),
    meta: {
      action: 'ROLE_CORE_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Cadastro Ordem de Serviço',
      breadcrumb: [
        {
          text: 'Ordem de Serviço'
        }
      ]
    },
    children: [
      {
        path: 'statusOsSettings',
        name: 'statusOsSettings',
        component: () =>
          import('@/views/settings/ordemServico/StatusOsSetting.vue'),
        meta: {
          action: 'ROLE_CORE_CONSULTAR',
          resource: 'ACL',
          pageTitle: 'Status OS',
          breadcrumb: [
            {
              text: 'Cadastros Módulo Ordem de Serviço | Status OS'
            }
          ]
        }
      }
    ]
  }
]
