import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryUrl = `/?page=${queryFilter.page - 1}&size=${queryFilter.size}&sort=${
      queryFilter.sort
    },${queryFilter.order}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisa?${queryFilter.filter}`
    }
    return axios.get(`/perfil${queryUrl}`)
  },
  insert(data) {
    return axios.post('/perfil/', data)
  },
  edit(data) {
    return axios.put(`/perfil/${data.id}`, data)
  },
  delete(id) {
    return axios.put(`/perfil/${id}`)
  },
  addPermission(id) {
    return axios.post(`/perfil/${id}/adicionar`)
  },
  RemovePermission(id) {
    return axios.post(`/perfil/${id}/remover`)
  }
}
