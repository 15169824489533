export default {
  LOAD_LIST_USERS(state, data) {
    state.listaUsers = data
  },
  PREPARE_TO_EDIT_USER(state, data) {
    state.user = data
  },
  RESET_USER(state) {
    state.user = {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      roles: [
        {
          id: ''
        }
      ]
    }
  },
  SET_SYSTEM_PERMISSION(state, data) {
    state.systemPermission = data
  }
}
