export default [
  {
    path: '/modules/convenios',
    name: 'convenio',
    component: () => import('@/views/modules/convenio/Convenio.vue'),
    meta: {
      action: 'ROLE_CORE_CONSULTAR',
      resource: 'ACL',
      pageTitle: 'Convênios',
      breadcrumb: [
        {
          text: 'Convênios'
        }
      ]
    }
  }
]
