import axios from '@axios'

export default {
  loadTable(queryFilter) {
    let queryUrl = ''
    queryFilter.order = 'desc'
    queryUrl = `?page=${queryFilter.page - 1}&size=${queryFilter.size}`
    if (queryFilter.filter) {
      queryUrl = `/pesquisar?${queryFilter.filter}&page=${
        queryFilter.page - 1
      }&size=${queryFilter.size}`
    }
    return axios.get(`/atendimento/atendimentos${queryUrl}`)
  },
  insert(data) {
    return axios.post('/atendimento/atendimentos', data)
  },
  insertAvulso(data) {
    return axios.post('/atendimento/atendimentos/avulso', data)
  },
  edit(data) {
    return axios.put(`/atendimento/atendimentos/${data.id}`, data)
  },
  delete(id) {
    return axios.delete(`/atendimento/atendimentos/${id}`)
  },
  listaAtendimentoPorIdRegistro(id) {
    return axios.get(`/atendimento/atendimentos/${id}/registro`)
  },
  searchProfessional(name) {
    const endpoint = `/atendimento/atendimentos/pesquisar_profissional?nome=${name}&page=0&size=10&sort=id,asc`
    return axios.get(endpoint)
  },
  searchPaciente(name) {
    const endpoint = `/atendimento/atendimentos/paciente?nome=${name}&page=0&size=10&sort=id,asc`
    return axios.get(endpoint)
  },
  getAgendaProfissional(id) {
    return axios.get(`/atendimento/agendamentos/calendario/${id}`)
  },
  gerarHorarioPorIdAgenda(id) {
    return axios.get(`/atendimento/agendamentos/agenda/${id}`)
  },
  getAtendimentoById(id) {
    return axios.get(`/atendimento/atendimentos/${id}`)
  },
  getListaCamposAtendimentoPorIdAtendimento(id) {
    return axios.get(`/atendimento/campo_atendimentos/atendimento/${id}`)
  },
  getListaProcedimentoPorIdProcedimento(id) {
    return axios.get(`/atendimento/campos_adicionais/lista/${id}`)
  },
  getListaProcedimentosPorEspecialidade(id) {
    return axios.get(`/atendimento/procedimentos/especialidade/${id}`)
  },
  editCamposAdicionais(data) {
    return axios.post('/atendimento/campo_atendimentos', data)
  },
  getListaCampoAdicionaisPorIdAtendimento(id) {
    return axios.get(`/atendimento/atendimentos/listar_campo/${id}`)
  },
  montarAbas(id) {
    return axios.get(`/atendimento/grupo_procedimento/atendimento/${id}`)
  },
  montarTela(data) {
    return axios.get(
      `atendimento/procedimentos/lista/${data.grupo}/${data.atendimento}`
    )
  },
  gerarHorarioPorIdProfissionalEData(data) {
    return axios.get(
      `/atendimento/agendamentos/calendario/horas?id=${data.id}&data=${data.data}`
    )
  },
  getQuantidadeVagasDisponiveis(data) {
    return axios.get(
      `/atendimento/atendimentos/qto_vagas_restante_por_espc?profissional=${data.id}&data=${data.data}&especialidade=${data.especialidade}`
    )
  }
}
